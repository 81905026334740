<template>
    <v-content class="follow-map">
    
        <div class="text-center pt-10 mb-5">
            <h1 class="subtitle-1 font-weight-bold pb-10" style="text-decoration: underline;">位置情報利用の許可</h1>
            <img src="@/assets/follow-map/location.svg">
        </div>
    
        <div class="px-3 mt-10">
            <p class="body-2 mb-8">現在地に基づく情報を受け取るには、位置情報の利用で「常に許可」を選択してください。</p>
    
            <div class="text-center">
                <div @click="nativePost({'aaa':'valueA','bbb':'valueB'})"><v-btn rounded class="maincolor-bg default_button mb-4">設定する</v-btn></div>
                <router-link :to="'mypage'">
                    <p class="caption">位置情報設定をskipする</p>
                </router-link>
            </div>
        </div>
    
    </v-content>
</template>

<script>
export default {
    data() {
        return {
        };
    }
};
</script>

<style scoped>
a {
    color: #333333;
}
</style>
